import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Header from './Header/Header';
import { Footer } from './Footer/Footer';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const data = useStaticQuery(query);

  return (
    <>
      <Header siteTitle={data.site?.siteMetadata.title} />
      <div className={styles.wrapper}>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Layout;
