import React from 'react';

export const Footer = () => {

  return (
    <>
      <hr/>
      <footer>
        Global Footer
      </footer>
    </>
  );
};
