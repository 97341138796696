import React from 'react';
import { Link } from 'gatsby-plugin-intl';

import styles from './Header.module.scss';

interface HeaderProps {
  siteTitle: string
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => (
  <header className={styles.header}>
    <div className={styles.header_left}>
      <a href="/" className={styles.logo}>{props.siteTitle}</a>
    </div>

    <div className={styles.header_right}>
      <Link activeClassName={styles.active} to="/">Home</Link>
      <Link activeClassName={styles.active} partiallyActive={true} to="/newsroom/">Newsroom</Link>
      <Link activeClassName={styles.active} partiallyActive={true} to="/career/">Career</Link>
      <Link activeClassName={styles.active} partiallyActive={true} to="/pages/">Pages</Link>
    </div>

    {/*  <LanguageSwitch/>*/}
  </header>
);

export default Header;
